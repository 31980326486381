<template>
  <div class="playbackListMob">
    <div :class="pindex==index?'act':''" v-for="(item, index) in JSON.parse(_playbackList)" :key="'playbackIndex'+index">
      <p @click="setPlaybackIndex(index)">{{item.title}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    _playbackList: String
  },
  data() {
    return {
      pindex: 0,
    }
  },
  methods: {
    setPlaybackIndex(index) {
      if(this.pindex == index){
        this.$toast("正在播放中", 600);
      } else {
        this.pindex = index;
        this.$emit('playbackIndex',index);
      }
    }
  }
}
</script>

<style lang="scss">
.playbackListMob {
  div:nth-of-type(1) {
    border-top: 1px solid #e6e6e6;
  }
  div {
    padding: 10px 0;
    border-bottom: 1px solid #e6e6e6;
    p {
      width: 100%;
      padding-left: 10px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      color: #909090;
      border-left: 2px solid transparent;
    }
  }
  div.act {
    p {
      color: #000000;
      border-image: linear-gradient(transparent 0%, #0a70c5 45%, #0a70c5 55%, transparent 100%) 1;
    }
  }
}
</style>